import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Table
} from 'reactstrap';
import { PanelHeader, StatisticsCustom } from '../../components';
import { Doughnut } from 'react-chartjs-2';
import { stats } from '../../helpers/actions/dashboard';
import { statusColors } from '../../helpers/nomenclators';
import { statusProjectChart } from './charts.jsx';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      statusProject: {
        labels: [],
        status: [],
        counts: []
      },
      projectsMonths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };
  }

  load() {
    let { dispatch } = this.props;

    dispatch(stats())
      .then(data => {
        let { statusProject, projectsMonths } = this.state,
          { t } = this.props;
        statusProject.labels = [];
        statusProject.status = [];
        statusProject.counts = [];

        data.projects.forEach(status => {
          statusProject.labels.push(t(status._id));
          statusProject.status.push(status._id);
          statusProject.counts.push(status.count);
        });

        this.setState({ data, statusProject, projectsMonths });
      })
      .catch(err => {});
  }

  componentWillMount() {
    this.load();
  }

  onStatus(element) {
    let { statusProject } = this.state;
    let { history } = this.props;

    if (element.length) {
      history.push(
        `/my-projects/by-status/${statusProject.status[element[0]._index]}`
      );
    }
  }

  render() {
    let { data, statusProject } = this.state,
      { t } = this.props;

    let statusChart = statusProjectChart(statusProject, statusColors, t);

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-stats card-raised">
                <CardBody>
                  <Row>
                    <Col xs={12} md={4}>
                      <StatisticsCustom
                        iconState="info"
                        icon="now-ui-icons design-2_ruler-pencil"
                        title={data.totalProjects}
                        subtitle={t('Projects')}
                        path={'/my-projects'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <StatisticsCustom
                        iconState="default"
                        icon="fa fa-user-circle"
                        title={data.contacts}
                        subtitle={t('Contacts')}
                        path={'/contacts'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <StatisticsCustom
                        iconState="warning"
                        icon="fa fa-calendar-o"
                        title={data.visits}
                        subtitle={t('Next Visits')}
                        path={'/calendar'}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <h6>
                    <Trans>Project States</Trans>
                  </h6>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={12} md={6}>
                      <Doughnut
                        onElementsClick={element => this.onStatus(element)}
                        data={statusChart.data}
                        options={statusChart.options}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Table responsive>
                        <tbody>
                          {statusProject.status.map((status, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  <span className={'mr-3'}>
                                    <i
                                      className={'fa fa-square'}
                                      style={{ color: statusColors[key] }}
                                    />
                                  </span>
                                  <Trans>{status}</Trans>
                                </td>
                                <td className="text-right">
                                  {statusProject.counts[key]}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Dashboard));
